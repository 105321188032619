import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import 'normalize.css/normalize.css'

import '/src/assets/scss/style.scss'

import {
  MoreButton,
  Map,
  Header,
  Layout,
  Footer,
  Contact
} from '/src/components'

const products = [
  {
    name: 'Fotowoltaika',
    img: () => <StaticImage src='../images/products/fv.png' />,
    heading: 'Rozwiązania dla fotowoltaiki',
    description: () => (
      <>
        Jesteśmy producentem stelaży gruntowych pod panele fotowoltaiczne.
        Dostępne do wysyłki, z dowozem do klienta.
        <br />
        <br />
        Produkujemy również carporty.
      </>
    )
  },
  // {
  //   name: 'Carporty',
  //   img: () => <StaticImage src='../images/products/fv.png' />,
  //   heading: 'Rozwiązania dla fotowoltaiki',
  //   description: () =>
  //     'W naszej ofercie znajdują się Carporty w różnych rozmiarach: na jedno auto, na dwa auta i na 6 samochodów w układzie 2x3'
  // },
  {
    name: 'Garaże',
    img: () => <StaticImage src='../images/products/garaze.png' />,
    heading: 'Garaże modułowe',
    description: () => (
      <>
        Produkujemy garaże w dwóch wymiarach: 3,5x6m i 5,8x6m. Konstrukcja
        stalowa sprawia, że są solidne, a zastosowana płyta warstwowa powoduje,
        że garaże są bardzo oszczędne i ciepłe. Mniejszy garaż idealnie sprawdzi
        się do parkowania jednego samochodu. Duży pozwoli zaparkować nawet dwa
        duże SUV'y. Duża ilość miejsca świetnie nadaje się również na przydomowe
        przechowywanie.
        <br />
        <br />W sprawie garaży wyślij do nas maila pod adres:&nbsp;
        <a href='mailto:garaze@stalproject.eu'>garaze@stalproject.eu</a>
      </>
    )
  },
  {
    name: 'Pawilony i budynki socjalne',
    img: () => <StaticImage src='../images/products/kontenery.png' />,
    heading: 'Budynki biurowe i socjalne',
    description: () =>
      'Nasze systemowe budynki biurowe, dzięki zastosowaniu lekkiej konstrukcji, można ustawiać w dowolnych konfiguracjach - w tym jedne na drugich. Oferujemy biura wykonane na konkretne zamówienie klienta. Jesteśmy w stanie dostosować układ okien, drzwi, dopasować balustrady, schody i ambony, a także dodać kolejne udogodnienia takie jak klimatyzacja, banki energii i panele fotowoltaiczne.'
  },
  {
    name: 'Pojemniki i kontenery',
    img: () => <StaticImage src='../images/products/pojemniki.png' />,
    heading: 'Pojemniki przemysłowe i kontenery',
    description: () =>
      'Produkujemy pojemniki przemysłowe na odpady i do innych zastosowań. Dostępne w dwóch rozmiarach spełnią najbardziej wygórowane oczekiwania. Praktyczne uszy pozwalają podczepić pojemniki od góry, szyny umożliwiają łatwe przenoszenie za pomocą wózków widłowych. Pojemniki można postawić jeden na drugim. Dostępne w różnych kolorach.'
  },
  {
    name: 'Konstrukcje przemysłowe',
    img: () => <StaticImage src='../images/products/konstrukcje.png' />,
    heading: 'Projektujemy i wykonujemy konstrukcje stalowe',
    description: () =>
      'Przez ostatnie lata wykonaliśmy kilkadziesiąt konstrukcji na zlecenie według projektów klienta. Ta liczba stale rośnie, gdyż nie boimy się podjąć przeróżnych wyzwań. Konstrukcje, które tworzymy są zgodne ze specyfikacją, solidne i mogą być zabezpieczone antykorozyjnie według potrzeb klientów (ocynkowane lub malowane).'
  },
  {
    name: 'Hale',
    img: () => <StaticImage src='../images/products/hale.png' />,
    heading: 'Lekkie konstrukcje halowe',
    description: () =>
      'Zajmujemy się kompleksową budową hal z lekkich konstrukcji stalowych i płyty warstwowej. Produkujemy konstrukcję, dostarczamy ją na miejsce i montujemy.'
  },
  {
    name: 'Domy systemowe',
    img: () => <StaticImage src='../images/products/domy.png' />,
    heading: 'Domy systemowe na zgłoszenie',
    description: () => (
      <>
        Ładne, oszczędne i praktyczne. Nasze domy na zgłoszenie występują w
        różnych wariantach i mogą być wyposażone w panele i magazyny energii,
        klimatyzację i inne praktyczne dodatki. W pełni wyposażona łazienka,
        sypialnia i salon z aneksem kuchennym spełnią oczekiwania najbardziej
        wymagających.
      </>
    ),
    link: '/domy'
  }
  // {
  //   name: 'Pawilony handlowe i gastronomiczne',
  //   img: () => <StaticImage src='../images/products/kontenery.png' />,
  //   heading: 'Pawilony handlowe i gastronomiczne',
  //   description: () =>
  //     'Jesteśmy producentem pawilonów handlowych i gastronomicznych'
  // }
  // {
  //   name: 'Pojemniki i kontenery',
  //   img: () => <StaticImage src='../images/products/pojemniki.png' />,
  //   heading: 'Pojemniki przemysłowe i kontenery',
  //   description: () =>
  //     'Produkujemy pojemniki przemysłowe na odpady i do innych zastosowań. Dostępne w dwóch rozmiarach spełnią najbardziej wygórowane oczekiwania. Praktyczne uszy pozwalają podczepić pojemniki od góry, szyny umożliwiają łatwe przenoszenie za pomocą wózków widłowych. Pojemniki można postawić jeden na drugim. Dostępne w różnych kolorach.'
  // },
  // {
  //   name: 'Konstrukcje przemysłowe',
  //   img: () => <StaticImage src='../images/products/konstrukcje.png' />,
  //   heading: 'Projektujemy i wykonujemy konstrukcje stalowe',
  //   description: () =>
  //     'Przez ostatnie lata wykonaliśmy kilkadziesiąt konstrukcji na zlecenie według projektów klienta. Ta liczba stale rośnie, gdyż nie boimy się podjąć przeróżnych wyzwań. Konstrukcje, które tworzymy są zgodne ze specyfikacją, solidne i mogą być zabezpieczone antykorozyjnie według potrzeb klientów (ocynkowane lub malowane).'
  // }
  // {
  //   name: 'Hale',
  //   img: () => <StaticImage src='../images/products/hale.png' />,
  //   heading: 'Lekkie konstrukcje halowe',
  //   description: () =>
  //     'Zajmujemy się kompleksową budową hal z lekkich konstrukcji stalowych i płyty warstwowej. Produkujemy konstrukcję, dostarczamy ją na miejsce i montujemy.'
  // }
  // {
  //   name: 'Wypałki',
  //   img: () => <StaticImage src='../images/products/wypalki.png' />,
  //   heading: 'Wypalamy usługowo',
  //   description: () =>
  //     'W naszym parku maszynowym znajduje posiadamy wypalarkę plazmową ze stołem roboczym o wymiarach 6000x2000mm. Dzięki nowoczesnej technologii jesteśmy w stanie wypalać precyzyjne otwory, unikać wyginania się elementów palonych z cienkich blach, a także, możemy uzyskać dużą dokładność wypałek nawet w grubych blachach.'
  // }
]
const projects = [
  {
    description:
      'Pojemniki przemysłowe, które produkujemy mogą być stawiane jeden na drugim, z łatwością przenoszone za pomocą wóżków widłowych, a także podczepiane od góry za pomocą haków.',
    img: () => <StaticImage src='../images/projects/1.webp' />
  },
  {
    description:
      'Na potrzeby najwyżeszego wieżowca w Polsce i Unii Europejskiej wykonaliśmy stopy i inne elementy konstrukcyjne.',
    img: () => <StaticImage src='../images/projects/2.webp' />
  },
  // {
  //   description:
  //     'Sit amet consectetuer euismod tincidunt ut laoreet ipsum dolor Adipiscing diamet nonum my nibh euism ichtincidunt',
  //   img: () => <StaticImage src='../images/projects/3.webp' />
  // },
  {
    description: 'Garaż modułowy 3,5x6m, z dwoma szybami. Kolor antracyt',
    img: () => <StaticImage src='../images/projects/4.webp' />
  }
]

const about = [
  {
    img: () => <StaticImage src='../images/about/1.webp' />
  },
  // {
  //   img: () => <StaticImage src='../images/about/2.webp' />
  // },
  {
    img: () => <StaticImage src='../images/about/3.webp' />
  },
  {
    img: () => <StaticImage src='../images/about/4.webp' />
  },
  // {
  //   img: () => <StaticImage src='../images/about/5.webp' />
  // },
  // {
  //   img: () => <StaticImage src='../images/about/6.webp' />
  // },
  {
    img: () => <StaticImage src='../images/about/7.webp' />
  }
]

const IndexPage = () => {
  const [activeProduct, setActiveProduct] = useState(-1)
  const [activeProject, setActiveProject] = useState(0)
  const [activeAbout, setActiveAbout] = useState(1)

  const toggleProduct = index => {
    if (index === activeProduct) {
      setActiveProduct(-1)
    } else {
      setActiveProduct(index)
    }
  }

  const nextProject = () => {
    setActiveProject((activeProject + 1) % projects.length)
  }

  const nextAbout = () => {
    setActiveAbout((activeAbout + 1) % about.length)
  }

  return (
    <Layout>
      <Helmet>
        <title>
          Stalproject - kompletne gruntowe systemy fotowoltaiczne, carporty,
          garaże, domy modułowe
        </title>
        <meta
          name='description'
          content='Produkujemy nowoczesne, solidne i lekkie gruntowe systemy montażowe dla instalacji fotowoltaicznych.'
        />
      </Helmet>
      <Header />
      <section className='hero'>
        <div className='wrapper'>
          {/* <a href='/domy' className='hero__link'> */}
          <div className='hero__backdrop'>
            {/* <div className='hero__see-more'>&laquo;- Zobacz więcej poniżej</div> */}
          </div>
          <div className='hero__background' />
          {/* <a href='#offer' className='hero__arrow'>
            <Arrow />
          </a> */}
          <div className='hero__claim'>
            <div className='hero__claim-bg' />
            <h1 className='hero__claim-context'>
              Gruntowe systemy fotowoltaiczne
            </h1>
          </div>
          {/* </a> */}
          <a name='offer' />
        </div>
      </section>
      <section className='content-section offer'>
        <div className='wrapper'>
          <header className='content-section__header'>
            <h2>Oferta</h2>
            <p>
              Jesteśmy producentem gruntowych systemów montażowych dla
              fotowoltaiki, carportów, garaży z płyty warstwowej i domów
              modułowych. Zapraszamy do zapoznania się z naszą ofertą poniżej.
            </p>
          </header>
          <div className='products'>
            {products.map(
              (
                {
                  name,
                  img = () => {},
                  heading,
                  description = () => {},
                  link = null
                },
                index
              ) => {
                return (
                  <div
                    className={`product ${
                      index === activeProduct ? 'product__active' : ''
                    }`}
                  >
                    <h3 className='product__name'>
                      {link ? <a href={link}>{name}</a> : name}
                    </h3>
                    <div className='product__wrapper'>
                      <div className='product__image'>
                        {link ? <a href={link}>{img()}</a> : img()}
                      </div>
                      <div className='product__overlay'>
                        <h4 className='product__heading'>{heading}</h4>
                        <div className='product__description'>
                          {description()}
                        </div>
                      </div>
                    </div>
                    <MoreButton
                      onClick={() => {
                        if (link) {
                          window.location.href = link
                        } else {
                          toggleProduct(index)
                        }
                      }}
                      arrow={index !== activeProduct}
                    >
                      {index !== activeProduct ? (
                        'Sprawdź szczegóły'
                      ) : (
                        <span className='product__toggle-overlay-close'>X</span>
                      )}
                    </MoreButton>
                    {/* </a> */}
                  </div>
                )
              }
            )}
            <a name='projects' />
          </div>
        </div>
        <a name='about' />
      </section>
      {/* <section className='content-section projects-section'>
        <div className='wrapper'>
          <header className='content-section__header'>
            <h2>Realizacje</h2>
            <p>
              Wykonaliśmy wiele projektów na zamówienie, niektóre realizacje
              przedstawiamy poniżej.
            </p>
          </header>
          {projects.length > 0 && (
            <div className='projects'>
              {projects.map(({ description, img = () => {} }, index) => (
                <div
                  className={`project ${
                    index === activeProject ? 'project__active' : ''
                  }`}
                >
                  <div className='project__image'>{img()}</div>
                  <p className='project__description'>{description}</p>
                  <span className='project__number'>
                    {index + 1} / {projects.length}
                  </span>
                </div>
              ))}
              <MoreButton onClick={nextProject}>Następna realizacja</MoreButton>
            </div>
          )}
          </div>
        </section> */}
      {/* <a name='about' /> */}
      {/* <section className='testimonials'></section> */}
      <section className='content-section about-section'>
        <div className='wrapper'>
          <header className='content-section__header'>
            <h2>O producencie</h2>
            <p>
              Posiadamy wieloletnie doświadczenie w wykonywaniu konstrukcji
              stalowych. Od ponad 3 lat dostarczamy na rodzimy rynek hurtowe
              ilości stelaży pod gruntowe instalacje fotowoltaiczne.
              <br />
              <br />
              Nieustanny rozwój pozwala nam opracowywać nowe rozwiązania
              technologiczne i proponować naszym klientom nowoczesne produkty
              takie jak domy systemowe, garaże z płyty warstwowej i carporty.
            </p>
          </header>
          {about.length > 0 && (
            <div className='about projects'>
              {about.map(({ img = () => {} }, index) => (
                <div
                  className={`project ${
                    index === activeAbout ? 'project__active' : ''
                  }`}
                >
                  {img()}
                  {/* <div className='project__description'> */}
                  {/* <h3>{heading}</h3> */}
                  {/* <p>{description}</p> */}
                  {/* </div> */}
                  <span className='project__number'>
                    {index + 1} / {about.length}
                  </span>
                </div>
              ))}
              <MoreButton onClick={nextAbout}>Więcej</MoreButton>
            </div>
          )}
        </div>
      </section>
      <a name='contact' />
      {/* <section className='cert'></section> */}
      <Contact map />
    </Layout>
  )
}

export default IndexPage
